@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;900&display=swap');


.loginRegister{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width:100vw;
    font-family: "Poppins";
    font-weight: 500;
    background-color:#1D9BF0;
    color: black;
}