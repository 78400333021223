body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: "Poppins";
}

.icon,.icon-1{
  cursor: pointer;
}

.icon:hover{
  color:#1D9BF0;
}
.icon-1:hover{
  color: #f91880 !important;
}

@media (max-width:750px) {
  *{
    display: none;
  }
  
}